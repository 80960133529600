.project {
  display: block;
  opacity: 1;
  margin-bottom: 200px;
  .project-card {
    display: flex;
    min-height: 350px;
    width: auto;
    overflow: hidden;
    position: relative;

    .image {
      position: absolute;
      padding-right: 20px;
      .overlay {
        display: none;
        background-color: rgba(0, 0, 0, 0.7);

        width: calc(100% - 20px);
        height: 100%;
        position: absolute;
        z-index: 1;
      }
      img {
        width: 680px;
       max-height: 350px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .project-content {
      * {
        background-color: transparent;
      }
      width: 100%;
      z-index: 99;
      background-color: transparent;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .sub-title {
        color: $orange;
        margin-bottom: 10px;
        font-size: 13px;
        font-family: "SF Mono";
      }
      h3 {
        color: $text;
        font-size: 23px;
        font-family: "Inter";
        margin-bottom: 20px;
      }

      .card {
        background-color: $light-background;
        box-shadow: 0px 10px 30px rgba(2, 12, 27, 0.7);
        border-radius: 4px;
        max-width: 550px;
        width: 100%;
        padding: 37px 50px;

        p {
          font-family: "Inter";
          font-size: 14px;
          color: $light-text;
          line-height: 23px;
          text-align: right;
        }
      }

      .tags {
        margin-top: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        li {
          margin-left: 10px;
          font-size: 13px;
          color: $text;
          transition: all 0.3s ease;
          cursor: default;
          // background-color: $background;
          // padding: 2px 5px;
          // border-radius: 4px;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .project-card-reverse {
    flex-direction: row-reverse;
    .image {
      padding-right: 0;
      padding-left: 20px;
    }
    .project-content {
      align-items: flex-start;
      text-align: left;
      margin-right: auto;
      margin-left: 0;
      .card {
        p {
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 875px) {
  .project-card {
    flex-direction: column !important;
    .image {
      position: relative !important;

      img {
        width: 100% !important;
      }
    }

    .project-content {
      padding: 0 10px;
      margin-top: 25px;

      .card {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0px !important;

      }
      .tags{
        li:first-child{
          margin-left: 0;
        }
      }
    }
  }
}
