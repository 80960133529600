.socialMediaIcons {
  display: none;
  opacity: 1;
  ul {
    list-style: none;
  }
}

@media (min-width: 768px) {
  .socialMediaIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 50px;
    a {
      margin-bottom: 20px;
      display: block;
      svg {
        width: 20px;
        height: 20px;
        fill: $text;
      }
      img{
        width: 25px;
        height: 25px;
      }
    }

    .line {
      width: 1px;
      height: 100px;
      background-color: $text;
      margin: 0 20px;
    }
  }
}
