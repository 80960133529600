.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  .page-not-found__title {
    font-size: 7rem;
    font-weight: 700;
    letter-spacing: 1rem;
    color: $orange;
    margin-bottom: 25px;
  }
  .page-not-found__subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: $text;
    margin-bottom: 25px;
    
  }
  .page-not-found__button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}
