.fixed-email {
  display: none;
  opacity: 1;
}

@media (min-width: 768px) {
  .fixed-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 50px;
    a {
      margin-bottom: 20px;
      display: block;
      writing-mode: vertical-lr;
      min-width: 20px;
    }

    .line {
      width: 1px;
      height: 100px;
      background-color: $text;
      margin: 0 20px;
    }
  }
}
