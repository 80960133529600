.blog-card {
  width: 300px;
  height: 305px;
  border-radius: 4px;
  background-color: $light-background;
  box-shadow: 0px 10px 30px rgba(2, 12, 27, 0.7);
  padding: 30px;
  margin: 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  overflow: auto !important;

  &:hover {
    box-shadow: 0px 10px 30px rgba(2, 12, 27, 0);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h4 {
    color: $text;
    font-size: 18px;
    font-family: "Inter";
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    color: $text;
    font-size: 14px;
    font-family: "Inter";
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ul {
    display: flex;
    height: 15px;
    flex-wrap: wrap;
    overflow-y: hidden;

    li {
      font-size: 10px;
      color: $light-text;
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }
}
