.blog {
  .blogs {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .btn {
    margin: 0 auto;
    margin-top: 45px;
    opacity: 1;
  }

}
.blog-loading{
  flex-direction: column;
  align-items: center;
  
}
