.contact {
  opacity: 1;
  margin-top: 75px;

  .sub-title {
    color: $orange;
  }

  h2 {
    font-family: "Inter";
    font-size: 50px;
    line-height: 66px;
    margin-bottom: 13px;
    margin-top: 20px;
    color: $text;
  }

  .content {
    max-width: 570px;
    width: 100%;
    font-family: "Inter";
    color: $light-text;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
