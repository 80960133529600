.btn {
  color: $orange;
  padding: 20px 40px;
  border: $orange solid 1px;
  border-radius: 4px;
  display: block;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  &:hover {
    background-color: $orange;
    color: $text;
  }
}
