.about {
  .about-content {
    opacity: 1;
    p {
      font-family: "Inter";
      line-height: 26px;
      font-size: 16px;
      color: $light-text;

      span {
        font-family: "Inter";
      }
    }
    .tech-list{
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      ul li{
        color: $light-text;
        margin-bottom: 10px;
      }
    }
  }

  .image-section{
    opacity: 1;
    align-items: flex-end;
    flex-direction: column;
    .image {
      padding: 20px;
      position: relative;
      width: fit-content;
      height: fit-content;
      margin-top: 20px;
      img {
        width: 300px;
        height: 298px;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
      }
      
      .image-background {
        width: 300px !important;
        height: 298px !important;
        border: $orange solid 5px;
        border-radius: 4px;
        z-index: -1;
      }
    }
    .certifications{
      align-items: flex-start;
      max-width: 340px;
      width: 100%;
      img{
        width: 75px;
      }
    }

  }
}
