.container {
  max-width: 1150px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 120px;
  }

  @media (min-width: 1250px) {
    padding: 0 75px;
  }

}
