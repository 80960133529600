.navbar {
  opacity: 1;
  margin-top: 32px;
  .bars {
    margin-left: auto;
    width: fit-content;
    display: block;
    cursor: pointer;

    div {
      background-color: $orange;
      width: 23px;
      height: 2px;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .line-2 {
      width: 18px;
    }
  }

  .nav-items {
    width: 0px;
    transition: all 1s;
    background-color: $background;
    box-shadow: 4px 8px 32px #00000050;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    padding: 32px 0;
    list-style: none;
    li {
      width: inherit;
      overflow: hidden;
      .close {
        padding: 15px;
        font-size: 14px;
        margin-left: auto;
        border-radius: 4px;
        width: fit-content;
        color: $orange;
        margin-right: 15px;
        transition: all 1s;
        cursor: pointer;

        &:hover {
          color: $text;
        }
      }
      a {
        width: inherit;
        padding: 15px;
        display: block;
        transition: all 1s;
        text-decoration: none;
        border: transparent solid 2px;

        &:hover {
          border: $orange dashed 2px;
        }

        span {
          color: $orange;
          margin-right: 5px;
        }
      }
    }
  }

  .active {
    width: 250px;
  }
}

@media (min-width: 768px) {
  .navbar {
    margin-left: auto;
    .bars {
      display: none;
    }
    .close {
      display: none;
    }

    .nav-items {
      position: relative;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      height: fit-content;
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
      width: 100%;

      li {
        width: fit-content;
        margin-left: 10px;
      }
    }
  }
}
