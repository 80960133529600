@font-face {
  font-family: "SF Mono";
  src: url("../../assets/fonts/SFMono-Regular.woff2");
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Regular.ttf");
}

h1 {
  margin-top: 30px;
  opacity: 1;
  font-size: 50px;
  color: $heading;
  margin-bottom: 6px;
  font-family: "Inter";
}

h2 {
  font-size: 27px;
}

p {
  font-size: 14px;
  color: $text;
}

a {
  text-decoration: none;
  font-size: 14px;
  color: $text;
}

.c-o {
  color: $orange;
}
