.landing {
  margin-top: 59px;

  p {
    font-size: 16px;
    opacity: 1;
  }
  h1 {
    margin-top: 30px;
    opacity: 1;
    font-size: 69px;
    color: $heading;
    margin-bottom: 6px;
    font-family: "Inter";
  }

  h2 {
    opacity: 1;
    font-size: 67px;
    color: #8892b0;
    margin-bottom: 27px;
    font-family: "Inter";
  }

  .desc {
    font-family: "Inter";
    max-width: 524px;
    width: 100%;
    color: #8892b0;
    line-height: 26px;
  }

  .landing-buttons {
    margin-top: 50px;
    opacity: 1;

    a:last-child {
      margin-top: 15px;
      display: block;
      margin-left: 3px;
      font-size: 12px;
      text-decoration: underline;
    }
  }
}
