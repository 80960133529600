.footer {
  margin: 25px 0;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: $light-text;
    text-align: center;
  }
}
